:root {
    --primary-color: #0b2401; 
    --card-bg-color:#FEF2EC;
    --button-color:#A03B08;
    --dark-color:#141816;
    --army-green-color:#353f34;
    --light-army-green-color:#EEF1EE;


    --main-card-bg-color:#3F8EEE;

    --log-blue-color:#0d4991;
    --log-yellow-color:#ffb63a;
    --log-red-color:#c91940;
    --log-green-color:#1b7132;

    --text-yellow-color:#f6c23e;

}


.log-red-color{color: var(--log-red-color);}
.white-text{color: white;}
.primary-text{color: var(--log-blue-color);}
.body-container{
    background: #f6f9ff; 
    border-radius: 10px;
    margin: auto;
    width: 90%;
    margin-bottom: 30px;
}
.nav-header-container{
    margin-top: 10px;
    background: black;  
    height: 40px;
    /* width: 100%; */
    border-top-right-radius: 10px;
}
.log-green-text{color: var(--log-green-color); font-weight: bold;}
.card-details-div{
    float: right;
    margin-top: -30px;
}

.card-next-icon{
    width: 30px; height: 30px;
    margin-top: -15px; 
}
.user-profile-div{
    margin-top: -35px;
    float: right;
}
.admin-profile-div{
    margin-right: -20px;
    float: right;
}
.align-items-center {
    align-items: center !important;}
.strong-text{padding-left: 10px;}
.custom-primary-color{color:#508e49 !important}
.custom-secondary-color{color:#074900 !important}
.custom-bg-primary-color{background-color:#508e49 !important}
.inner-body-container{margin-left: 10px; margin-right: 10px; margin-top: 15px; }

.custom-alert-btn-actions{
    float:right;
    margin-top: -10px;
}
.research-preview-paragraph{
    padding-left: 10px;
    /* text-transform: uppercase; */
}
.research-preview-title{
    font-weight: bold;
    text-transform: uppercase;
}
.custom-alert-card{
    border-radius: 8px;
    height: 40px;
}
.form-inputs
{
    /* border: none;
     */border-radius: 5px !important;
    border: 2px solid var(--army-green-color) !important;


}
.primary-text
{
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}
.form-inputs:focus {
    border: 3px solid var(--log-yellow-color) !important;
}
.logo-icon{
  margin-top: -20px;
  border-radius: 20px;
  height: 150px;
}
.selected-upload-image{width: 120px;}
.user-profile-card{margin-left: 15px !important; margin-right: 15px !important;}
.user-icon{
    /* margin-top: -10px; */
    border-radius: 20px;
    width: 30px;
    height: 30px;
    /* padding-right: 20px; */
}
.table-next-icon{
    width: 30px; height: 30px;
}
.my-grid-container-2-columns
{
    display: grid;
    grid-template-columns: auto auto;
}

.my-grid-container-3-columns
{
    display: grid;
    grid-template-columns: auto auto auto;
}

.my-grid-container-4-columns
{
    display: grid;
    grid-template-columns: auto auto auto auto;
}
.my-grid-container-5-columns
{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.my-grid-item-user {padding: 2px;  color: white;}
.my-grid-item 
{
    padding: 5px;
    font-size: 5px;
}


/* Admin */
.nav-dropdown-container
{
    background: #05668d;
    border-radius: 10px;
    margin-left: 7px;
    margin-right: 10px;
    /* 
     */
    /* padding-left: 20px; */
}
.admin-table-user-icon
{
    height: 50px
}



.col-auto {
flex: 0 0 auto;
width: auto;
max-width: 100%;
}
.border-left-primary {
border-left: 5px solid #4e73df !important;
border-right: 5px solid #4e73df !important;
border-top: 1px solid #4e73df !important;
border-bottom: 1px solid #4e73df !important;
cursor: pointer;

}
.researchers-card{ border-radius: 15px !important; background-color: var(--card-bg-color) !important;}
.researchers-grid-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);   
    gap: 10px;
}
.black-bg-color{background-color: black !important;color: white !important;}

.researchers-grid-item{width: 100%;}

.research-display-card
{ 
    /* background-color: red !important; */
    border: 2px solid var(--log-blue-color) !important;
    /* border-radius: 20px !important; */
    background-color: whitesmoke !important;
}
.border-bottom-primary {
border-bottom: 5px solid #4e73df !important;
}

.border-left-secondary {
border-left: 5px solid #858796 !important;
}

.border-bottom-secondary {
border-bottom: 5px solid #858796 !important;
}

.border-left-success {
border-left: 5px solid #1cc88a !important;
border-right: 5px solid #1cc88a  !important;
border-top: 1px solid #1cc88a  !important;
border-bottom: 1px solid #1cc88a  !important;
cursor: pointer;
}

.border-bottom-success {
border-bottom: 5px solid #1cc88a !important;
}

.border-left-info {
border-left: 5px solid #36b9cc !important;
border-right: 5px solid #36b9cc   !important;
border-top: 1px solid #36b9cc   !important;
border-bottom: 1px solid #36b9cc   !important;
cursor: pointer;
}

.border-bottom-info {
border-bottom: 5px solid #36b9cc !important;
}

.border-left-warning {
border-left: 5px solid #f6c23e !important;
border-right: 5px solid #f6c23e   !important;
border-top: 1px solid #f6c23e   !important;
border-bottom: 1px solid #f6c23e   !important;
cursor: pointer;
}

.border-bottom-warning {
border-bottom: 5px solid #f6c23e !important;
}

.border-left-danger {
border-left: 5px solid #e74a3b !important;
}

.border-bottom-danger {
border-bottom: 5px solid #e74a3b !important;
}

.border-left-light {
border-left: 5px solid #f8f9fc !important;
}

.border-bottom-light {
border-bottom: 5px solid #f8f9fc !important;
}

.border-left-dark {
border-left: 5px solid #5a5c69 !important;
}

.border-bottom-dark {
border-bottom: 5px solid #5a5c69 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .register-grid-container-2-columns,.my-grid-container-2-columns
        {grid-template-columns: auto;}
    .researchers-grid-container{grid-template-columns: repeat(1, 1fr);}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    /* .researchers-grid-container{grid-template-columns: repeat(2, 1fr);} */

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    /* .researchers-grid-container{grid-template-columns: repeat(3, 1fr);} */

} 

/* Large devices (laptops/desktops, 995px and up) */
@media only screen and (min-width: 995px) {
.example {background: orange;}
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
.example {background: pink;}
}